import React from 'react';

import App from '../../components/App';
import { EmptyPageModern } from '../../components/Common/styled/EmptyPage';
import { PlansView } from '../../components/Plan/PlansView';

export default () => (
  <App>
    <EmptyPageModern big>
      <PlansView />
    </EmptyPageModern>
  </App>
);
