import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CHANGE_BILLING_PLAN } from '../../graphql/mutations';
import { GET_USER_BILLING_PLAN } from '../../graphql/queries';
import Loading from '../Common/Loading';
import { AlertUI } from '../../componentsUI/Alert';
import { Form } from '../Common/styled/Form';
import { TextFieldUI } from '../../componentsUI/TextField';
import { TextFieldSelectUI } from '../../componentsUI/TextFieldSelect';
import { countryList } from '../../../locales/countries';
import { getLocaleSortedSelectList } from '../../utils/SelectListUtils';
import { Span } from '../Common/styled/Text';
import { HighlightedText } from '../Common/styled/HighlightedText';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      maxWidth: 800,
      [theme.breakpoints.down('sm')]: {
        margin: 16,
      },
      [theme.breakpoints.down('xs')]: {
        padding: 8,
        margin: 8,
      },
    },
    '& .MuiDialogTitle-root': {
      [theme.breakpoints.down('xs')]: {
        padding: 8,
      },
      '& > .MuiTypography-root': {
        fontWeight: 800,
      },
    },
    '& .MuiDialogContent-root': {
      padding: 8,
      '&::-webkit-scrollbar': {
        width: 3,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.light,
      },
    },
  },
  form: {
    margin: '12px 0 8px',
    '& .MuiGrid-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiTextField-root': {
      width: 'calc(100% - 16px)',
      minHeight: 67,
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primary.light,
    },
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  order: {
    padding: '8px 24px',
  },
  section: {
    fontWeight: 700,
    marginBottom: 6,
  },
  expiration: {
    color: '#909090',
  },
  button: {
    padding: '8px 25px',
    fontWeight: 700,
  },
  cardLast: {
    marginBottom: 24,
  },
  downgrade: {
    padding: '0 24px 8px',
  },
}));

const padLastCreditCardNumbers = (num, size = 4) => {
  let code = num && num.toString();
  while (code && code.length < size) code = `0${code}`;
  return code;
};

export const PlansFormDialog = ({ open, onClose, info, formName = 'plans-form' }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [company, setCompany] = useState('individual');
  const [error, setError] = useState(false);

  const upgrade = info && info.selectedPlan && info.selectedPlan.price > info.dataUserPlan.userBillingPlan.billingPlan.price;
  const address = info.dataUserPlan.userBillingPlan.address;
  const card = info.dataUserPlan.userBillingPlan.addonPaymentsCardRef;
  const cardLast = padLastCreditCardNumbers(info.dataUserPlan.userBillingPlan.cardLastNumbers, 4);
  const selectCountryList = getLocaleSortedSelectList(countryList);
  const currentYear = new Date().getFullYear();

  const [changeBillingPlan, { loading: updating }] = useMutation(CHANGE_BILLING_PLAN, {
    onCompleted: () => {
      navigate('/user/settings/plan');
    },
    refetchQueries: [{
      query: GET_USER_BILLING_PLAN,
    }],
    awaitRefetchQueries: true,
  });

  const cardShape = upgrade && !card ? ({
    cardName: Yup.string().required(t('required.field')),
    cardNumber: Yup.string()
      .required(t('required.field'))
      .matches(/^\d+$/, t('only.numbers.allowed'))
      .matches(/^(.{15,22})$/, t('card.must.have.between.15.and.22.digits')),
    cardExpirationMonth: Yup.string()
      .required(t('required.field'))
      .matches(/^\d+$/, t('only.numbers.allowed'))
      .matches(/^(.{2})$/, t('date.must.have.two.digits'))
      .test('months', t('invalid.month.value'), (val) => parseInt(val, 10) >= 1 && parseInt(val, 10) <= 12),
    cardExpirationYear: Yup.string()
      .required(t('required.field'))
      .matches(/^\d+$/, t('only.numbers.allowed'))
      .matches(/^(.{2})$/, t('date.must.have.two.digits'))
      .test('year', t('invalid.year.value'), (val) => parseInt(`20${val}`, 10) >= currentYear),
    cardCVC: Yup.string()
      .required(t('required.field'))
      .matches(/^\d+$/, t('only.numbers.allowed'))
      .matches(/^(.{3,4})$/, t('cvc.must.have.three.or.four.digits')),
  }) : {};

  const companyShape = upgrade && !address && company === 'company' ? ({
    companyName: Yup.string().required(t('required.field')),
  }) : {};

  const addressShape = upgrade && !address ? ({
    documentId: Yup.string().required(t('required.field')),
    countryCode: Yup.string().required(t('required.field')),
    province: Yup.string().required(t('required.field')),
    town: Yup.string().required(t('required.field')),
    postalCode: Yup.string().required(t('required.field')),
    address: Yup.string().required(t('required.field')),
  }) : {};

  const validationSchema = Yup.object().shape({
    ...cardShape,
    ...companyShape,
    ...addressShape,
  });

  const initialValues = {
    company: 'individual',
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleSubmit = async (values) => {
    setError(false);

    try {
      const input = {
        uuid: info.selectedPlan.uuid,
        ...values,
      };
      input.company = company === 'company';
      await changeBillingPlan({ variables: { input } });
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) setError(errors.graphQLErrors[0].message);
    }
  };

  return info && info.selectedPlan && (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{`${t('you.are.almost.on')} ${info.selectedPlan.name}...`}</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form autoComplete="off" id={formName} onSubmit={props.handleSubmit}>
              {!upgrade && (
                <Grid container spacing={1} item xs={12} className={classes.downgrade}>
                  <Typography>{`${t('you.are.about.to.downgrade.your.plan.to')} ${info.selectedPlan.name}`}</Typography>
                  <Typography>{`${t('the.change.will.take.place.on')}: ${info.dataUserPlan.userBillingPlan.nextBillingDate}`}</Typography>
                </Grid>
              )}
              {upgrade && (
                <Grid container spacing={1} className={classes.form}>
                  <Grid item xs={12}>
                    <Typography className={classes.section}>
                      {`${t('payment.details')}: `}
                    </Typography>
                  </Grid>
                  {card && (
                    <Grid item xs={12}>
                      <Typography className={classes.cardLast}>
                        {`${t('card.ending.in')} `}
                        <HighlightedText>
                          <b>{cardLast}</b>
                        </HighlightedText>
                      </Typography>
                    </Grid>
                  )}
                  {!card && (
                    <>
                      <Grid item xs={12}>
                        <TextFieldUI
                          name="cardName"
                          label={t('card.holder.name')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldUI
                          name="cardNumber"
                          type="text"
                          label={t('card.number')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextFieldUI
                          name="cardExpirationMonth"
                          label="MM"
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextFieldUI
                          name="cardExpirationYear"
                          label="YY"
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldUI
                          name="cardCVC"
                          label="CVC"
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography className={classes.section}>
                      {`${t('billing.address')}:`}
                    </Typography>
                  </Grid>
                  {info.dataUserPlan.userBillingPlan.address && (
                    <Grid item xs={12} className={classes.form}>
                      <Typography>{`${t(info.dataUserPlan.userBillingPlan.countryCode)}, ${info.dataUserPlan.userBillingPlan.province}`}</Typography>
                      <Typography>{`${t(info.dataUserPlan.userBillingPlan.town)}, ${info.dataUserPlan.userBillingPlan.postalCode}`}</Typography>
                      <Typography>{`${info.dataUserPlan.userBillingPlan.address}`}</Typography>
                    </Grid>
                  )}
                  {!info.dataUserPlan.userBillingPlan.address && (
                    <>
                      <Grid item xs={12} className={classes.form}>
                        <RadioGroup row aria-label="company" name="company" value={company} onChange={handleCompanyChange}>
                          <FormControlLabel control={<Radio />} value="individual" label={t('individual')} />
                          <FormControlLabel control={<Radio />} value="company" label={t('company')} />
                        </RadioGroup>
                      </Grid>
                      {company === 'company' && (
                        <Grid item xs={12}>
                          <TextFieldUI
                            name="companyName"
                            label={t('company.name')}
                            props={props}
                            classes={classes}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextFieldUI
                          name="documentId"
                          label={t('document.id')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldSelectUI
                          options={selectCountryList}
                          name="countryCode"
                          label={t('country')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldUI
                          name="province"
                          label={t('province')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextFieldUI
                          name="town"
                          label={t('town')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldUI
                          name="postalCode"
                          label={t('postal.code')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldUI
                          name="address"
                          label={t('address')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Form>
          )}
        </Formik>
      </DialogContent>
      {updating && <Loading />}
      {error && <AlertUI severity="error" title="Error">{error}</AlertUI>}
      {upgrade && (
        <Grid container spacing={1} className={classes.order}>
          <Grid item xs={12}>
            <Typography className={classes.section}>
              {`${t('order.summary')}:`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className="mb-4" style={{ width: '300px' }}>
              <div className="d-flex justify-content-between">
                <Span>{`${info.selectedPlan.name} ${t('plan')}`}</Span>
                <Span>{`${info.selectedPlan.price}€`}</Span>
              </div>
              <div className="d-flex justify-content-between">
                <Span>{t('unconsumed.balance')}</Span>
                <Span>{`-${info.unconsumedBalance.toFixed(2)}€`}</Span>
              </div>
              <div className="d-flex justify-content-between">
                <Span><b>{t('total')}</b></Span>
                <Span>{`${(info.selectedPlan.price - info.unconsumedBalance).toFixed(2)}€`}</Span>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={onClose}
        >
          {t('back')}
        </Button>
        <Button
          form={formName}
          variant="contained"
          className={classes.button}
          color="primary"
          type="submit"
        >
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
