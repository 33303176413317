import styled from 'styled-components';

const labelHeight = '36px';
const labelMaxWidth = '170px';
const labelTriangleBaseWidth = '15px';
const labelBorderRadius = '.625em 0 0 .3125em';
const labelLeftBoxShadow = '-4px 3px 4px #0006';

export const BadgeLabel = styled.div`
  position: relative;
  z-index: 5;
  padding: 0 1.5em 0 1.75em;
  height: ${labelHeight};
  max-width: ${labelMaxWidth};
  line-height: ${labelHeight};
  font-size: 1.125em;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  background-color: ${(props) => (props.labelBackgroundColor ? `${props.labelBackgroundColor}bc` : `${props.theme.colors.primary}bc`)};
  border-radius: ${labelBorderRadius};
  box-shadow: ${labelLeftBoxShadow};
`;

export const BadgeLabelContainer = styled.div`
  margin: 0.25em auto .75em -1.4375em;
`;

export const BadgeLabelWrapper = styled.div`
  display: inline;
  float: left;
  height: ${labelHeight};
  max-height: ${labelMaxWidth};
  background-color: #000000;
  border-width: 0;
  border-radius: ${labelBorderRadius};
`;

export const BadgeLabelWrapperAfter = styled.div`
  display: inline;
  float: left;
  width: 0;
  height: 0;
  border-bottom: ${labelHeight} solid #000000;
  border-right: ${labelTriangleBaseWidth} solid transparent;
`;

export const BadgeLabelAfter = styled.div`
  display: inline;
  position: relative;
  z-index: 2;
  float: left;
  width: 0;
  height: 0;
  border-bottom: ${labelHeight} solid ${(props) => (props.labelBackgroundColor ? `${props.labelBackgroundColor}bc` : `${props.theme.colors.primary}bc`)};
  border-right: ${labelTriangleBaseWidth} solid transparent;
  box-shadow: ${labelLeftBoxShadow};
`;
