import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { BadgeLabel, BadgeLabelAfter, BadgeLabelContainer, BadgeLabelWrapper, BadgeLabelWrapperAfter } from '../Common/styled/BadgeLabel';
import { DivCentered, Span } from '../Common/styled/Text';
import { PlanColumn, PlanContainerRow, PlanDiv } from '../Common/styled/PlanDiv';
import { KBytesPrettify } from '../../utils/BytesPrettifyUtils';
import { RoundedButton } from '../Common/styled/Button';
import { InnerSpanHTML } from '../Common/InnerHTML';

const PlanDescriptionColumn = ({ billingPlan }) => {
  const { t } = useTranslation();
  return (
    <PlanColumn className="my-3 pr-3" align="left" fontSize=".875em">
      {!!Number(billingPlan.users) && <PlanDescription value={billingPlan.users} description={t('users.for.your.hospitals')} />}
      {!!Number(billingPlan.storage) && <PlanDescription value={KBytesPrettify(billingPlan.storage).string} description={t('data.storage')} />}
      {!!Number(billingPlan.dicomDownload) && <PlanDescription value={KBytesPrettify(billingPlan.dicomDownload).string} description={t('dicom.download')} />}
      {!!Number(billingPlan.credits) && <PlanDescription value={billingPlan.credits} description={t('credits')} />}
      {!!Number(billingPlan.supportHours) && <PlanDescription value={billingPlan.supportHours} description={t('support.hours')} />}
    </PlanColumn>
  );
};

export const PlanViewDiv = (props) => {
  const { t } = useTranslation();
  const { billingPlan, dataUserPlan, setSelectedPlan } = props;
  const isCurrentPlan = billingPlan.uuid === dataUserPlan.userBillingPlan.billingPlan.uuid;

  return (!billingPlan.hideInDefaultView || isCurrentPlan ? (
    <PlanDiv isCurrentPlan={isCurrentPlan} className="flex text-center border px-3 py-4 m-4">
      <div className="d-flex flex-column">
        <PlanLabel isCurrentPlan={isCurrentPlan} billingPlan={billingPlan} />
        <DivCentered minHeight="2.5em">
          <InnerSpanHTML content={billingPlan.description} />
        </DivCentered>
        <PlanPrice price={billingPlan.price} />
        <p className="m-0 mt-1 mb-4"><b>{t('features.included')}</b></p>
        <PlanDescriptionColumn className="my-3 pr-3" billingPlan={billingPlan} />
      </div>
      <RoundedButton className="w-75 mb-4 mt-5 mx-auto" disabled={isCurrentPlan} onClick={() => setSelectedPlan(billingPlan)}>
        {billingPlan.uuid === dataUserPlan.userBillingPlan.billingPlan.uuid ? t('current.plan') : t('select.plan')}
      </RoundedButton>
    </PlanDiv>
  ) : null);
};

export const PublicPlanViewDiv = (props) => {
  const { t } = useTranslation();
  const { billingPlan } = props;

  return (
    <PlanDiv isCurrentPlan={false} className="flex text-center border px-3 py-4 m-4">
      <div className="d-flex flex-column">
        <PlanLabel isCurrentPlan={false} billingPlan={billingPlan} />
        <DivCentered minHeight="2.5em">
          <InnerSpanHTML content={billingPlan.description} />
        </DivCentered>
        <PlanPrice price={billingPlan.price} />
        <p className="m-0 mt-1 mb-4"><b>{t('features.included')}</b></p>
        <PlanDescriptionColumn className="my-3 pr-3" billingPlan={billingPlan} />
      </div>
      <RoundedButton className="w-75 mb-4 mt-5 mx-auto" onClick={() => (!billingPlan.defaultForNewUsers ? navigate(`/register?selectedPlan=${billingPlan.uuid}`) : navigate('/register'))}>
        {t('select.plan')}
      </RoundedButton>
    </PlanDiv>
  );
};

export const PlanLabel = (props) => {
  const { isCurrentPlan, billingPlan, backgroundColor } = props;
  const labelBackgroundColor = isCurrentPlan ? '#b7b7b7' : backgroundColor;
  return (
    <BadgeLabelContainer>
      <BadgeLabelWrapper>
        <BadgeLabel labelBackgroundColor={labelBackgroundColor}>{billingPlan.name.toUpperCase()}</BadgeLabel>
      </BadgeLabelWrapper>
      <BadgeLabelWrapperAfter>
        <BadgeLabelAfter labelBackgroundColor={labelBackgroundColor} />
      </BadgeLabelWrapperAfter>
    </BadgeLabelContainer>
  );
};

export const PlanPrice = ({ price }) => {
  const { t } = useTranslation();
  return (price > 0 ? (
    <Span className="mt-0 mb-5" fontSize="2.5em" fontWeight="800">
      {`${price}€`}
      <Span fontSize=".375em" fontWeight="800" className="px-1">/</Span>
      <Span fontSize=".4375em" fontWeight="800">{`${t('month')}`}</Span>
    </Span>
  ) : (
    <Span className="mt-0 mb-5" fontSize="2.25em" fontWeight="800">{t('price.free').toUpperCase()}</Span>
  ));
};

export const PlanDescription = ({ value, description }) => (
  <PlanContainerRow className="w-100 text-left" align="left">
    <div className="m-0 mb-1">
      <Span className="mt-0 mr-2 text-right" fontWeight="800" minWidth="3.75em" float="left">{value}</Span>
    </div>
    <div className="m-0 mb-1">
      {description}
    </div>
  </PlanContainerRow>
);
