import styled, { css } from 'styled-components';

const BaseDiv = styled.div`
  display: flex;
  flex: 1;
`;

export const PlanContainerRow = styled(BaseDiv)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
`;

export const PlanColumn = styled(BaseDiv)`
  flex-direction: column;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
`;

export const PlanDiv = styled.div`
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  
  background-color: ${(props) => (props.isCurrentPlan ? '#f6f8fa' : props.theme.colors.white)};
  padding: ${(props) => (props.padding ? props.padding : props.theme.cardPadding)};
  cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
  min-width: 200px; 
  max-width: 250px !important; 
  box-shadow: #0006 -6px 8px 18px;
  border-radius: 16px;
  ${(props) => !props.noBorder && css`
    border-bottom: ${props.theme.cardBorder};
  `}
`;

export const PlanDivLeftMargin = styled.div`
  margin-left: auto !important;
`;

export const PlanDivRightMargin = styled.div`
  margin-right: auto !important;
`;
