import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import { useQueryParam, StringParam } from 'use-query-params';

import { FullViewHeader as Header } from '../../components/Common/FullViewHeader';
import { PlanContainerRow } from '../Common/styled/PlanDiv';
import Loading from '../Common/Loading';

import { GET_BILLING_PLANS, GET_USER_BILLING_PLAN } from '../../graphql/queries';

import { PlanViewDiv } from './PlanLabel';
import { PlansFormDialog } from './PlansFormDialog';

export const PlansView = () => {
  const { t } = useTranslation();
  const [selectedPlanQueryParam] = useQueryParam('selectedPlan', StringParam);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const { loading: loadingPlans, data: dataPlans } = useQuery(GET_BILLING_PLANS);
  const { loading: loadingUserPlan, data: dataUserPlan } = useQuery(GET_USER_BILLING_PLAN);

  useEffect(() => {
    if (dataPlans && dataPlans.billingPlans) {
      const sPlan = dataPlans.billingPlans.find((plan) => plan.uuid === selectedPlanQueryParam);
      if (sPlan) setSelectedPlan(sPlan);
    }
  }, [selectedPlanQueryParam, dataPlans]);

  if (loadingPlans || loadingUserPlan) return <Loading />;
  if (!(dataUserPlan && dataUserPlan.userBillingPlan && dataUserPlan.userBillingPlan.nextBillingDate)) return <Loading />;

  const nextBillingDate = moment(dataUserPlan.userBillingPlan.nextBillingDate).utc();
  const now = moment.tz();
  const daysUsed = 30 - nextBillingDate.diff(now, 'days');
  const unconsumedBalance = dataUserPlan.userBillingPlan.billingPlan.price * (1 - daysUsed / 30);

  return (
    <div>
      <Header />
      <h2 className="text-center">{t('choose.a.plan.that.works.for.you')}</h2>
      <PlanContainerRow className="mt-4">
        {dataPlans && dataPlans.billingPlans && dataPlans.billingPlans.map((billingPlan, i) => (
          <PlanViewDiv billingPlan={billingPlan} dataUserPlan={dataUserPlan} setSelectedPlan={setSelectedPlan} key={i.toString()} />
        ))}
      </PlanContainerRow>
      <div className="text-center mt-5">
        <b>{`${t('need.a.bigger.solution')}? `}</b>
        <a href="https://alma-medical.com/en/contact/" target="blank">{`${t('contact.the.sales.team')}.`}</a>
      </div>
      <PlansFormDialog
        open={!!selectedPlan}
        onClose={() => setSelectedPlan(null)}
        info={{ ...{ selectedPlan, dataUserPlan, unconsumedBalance } }}
      />
    </div>
  );
};
